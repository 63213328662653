import axios from "axios";

const api = axios.create({
  baseURL: "https://company-api.salesbreath.com.br/api/v1",
  //baseURL: "http://companyhomologacao.salesbreath.com.br:3010/api/v1/",
  //baseURL: "http://localhost:3008/api/v1/",
  headers: { "Content-Type": "application/json" }
});

export default api;
